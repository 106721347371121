import { useState, useEffect } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import NotificationBar from './NotificationBar'; // 引入通知栏组件
import SignUp from "../SignUp";
import SignIn from "../SignIn";
import Forget from "../Forget";
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material'; // Import MUI Button
import {
    HeaderSection,
    LogoContainer,
    Burger,
    NotHidden,
    Menu,
    CustomNavLinkSmall,
    Label,
    Outline,
    Span,
    CustomSpan,
    DrawerMenu,
    NavItems,
    LanguageIconContainer
} from "./styles";
import { CSSTransition } from "react-transition-group";
import "./transitions.css";
import { useAuth } from "../../context/AuthContext";
import styled, { createGlobalStyle } from "styled-components";
import i18n from "../../i18n";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Motiva Sans Light';
        src: url('/public/fonts/Motiva-Sans-Light.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Motiva Sans Bold';
        src: url('/public/fonts/Motiva-Sans-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }

    body {
        font-family: 'Motiva Sans Light';
    }
`;

const BoldSpan = styled(Span)`
    font-family: 'Roboto', sans-serif; // Use Roboto font
    font-weight: bold; // Set the weight to bold
    font-size: 1rem
`;


const CustomDrawer = styled(Drawer)`
    @media only screen and (max-width: 890px) {
        .ant-drawer-content-wrapper {
            width: 50% !important;
        }
    }
`;

const LanguageIcon = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: 16px;
    vertical-align: middle;
`;

const Header = ({ t }: { t: TFunction }) => {
    const [visible, setVisibility] = useState(false);
    const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);
    const [isSignInModalVisible, setIsSignInModalVisible] = useState(false);
    const [isForgetModalVisible, setIsForgetModalVisible] = useState(false);
    const { isAuthenticated } = useAuth();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const location = useLocation();
    const [showNotificationBar, setShowNotificationBar] = useState(false);
    
    useEffect(() => {
        const shouldShowNotificationBar = location.pathname === '/' || location.pathname === '/home';
        setShowNotificationBar(shouldShowNotificationBar);
    }, [location.pathname]);

    const toggleButton = () => {
        setVisibility(!visible);
    };

    const showSignUpModal = () => {
        setVisibility(false);
        setIsSignUpModalVisible(true);
        setIsSignInModalVisible(false);
        setIsForgetModalVisible(false);
    };

    const showSignInModal = () => {
        setVisibility(false);
        setIsSignUpModalVisible(false);
        setIsSignInModalVisible(true);
        setIsForgetModalVisible(false);
    };

    const showForgetModal = () => {
        setVisibility(false);
        setIsSignUpModalVisible(false);
        setIsSignInModalVisible(false);
        setIsForgetModalVisible(true);
    };

    const handleCancel = () => {
        setIsSignUpModalVisible(false);
        setIsSignInModalVisible(false);
        setIsForgetModalVisible(false);
    };

    const navigateTo = (url: string) => {
        window.location.href = url;
        setVisibility(false);
    };

    const handleTryForFreeClick = () => {
        if (isAuthenticated) {
            navigateTo("/tool");
        } else {
            showSignUpModal();
        }
    };

    const switchLanguage = () => {
        const newLanguage = currentLanguage === 'en' ? 'zh' : 'en';
        setCurrentLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    useEffect(() => {
        setCurrentLanguage(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        const handleShowSignUpModal = () => {
            setIsSignUpModalVisible(true);
            setIsSignInModalVisible(false);
            setIsForgetModalVisible(false);
        };

        window.addEventListener("showSignUpModal", handleShowSignUpModal);

        return () => {
            window.removeEventListener("showSignUpModal", handleShowSignUpModal);
        };
    }, []);

    const MenuItem = () => {
        return (
            <NavItems>
                <CustomNavLinkSmall onClick={handleTryForFreeClick}>
                    <BoldSpan>{"Get Started"}</BoldSpan>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => window.open('https://wa.me/message/2D2FV2BZRHB4P1', '_blank')}>
                    <BoldSpan>{"Contact Us"}</BoldSpan>
                </CustomNavLinkSmall>
                {isAuthenticated ? (
                <Button
                    variant="contained"
                    onClick={() => navigateTo("/account")}
                    sx={{
                    margin: {
                        xs: "1rem 0", 
                        sm: "1.25rem 0", 
                        md: "1rem 2rem",
                    },
                    backgroundColor: "#FF5400",
                    borderRadius: "10px",
                    "&:hover": {
                        backgroundColor: "#E04A00",
                    },
                    textTransform: "none",
                    fontSize: "1rem",
                    }}
                >
                    {t("My Account")}
                </Button>
                ) : (
                <Button
                    variant="outlined"
                    onClick={showSignInModal}
                    sx={{
                    margin: {
                        xs: "1rem 0",
                        sm: "1.25rem 0",
                        md: "1rem 2rem",
                    },
                    borderRadius: "10px",
                    "&:hover": {
                        backgroundColor: "#E04A00",
                        color: "white",
                    },
                    textTransform: "none",
                    color: "#FF5400",
                    borderColor: "#FF5400",
                    fontSize: "1rem",
                    px: 3,
                    }}
                >
                    {t("Log In")}
                </Button>
                )}


                <Span>
                    <CSSTransition
                        in={isSignUpModalVisible}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <SignUp visible={isSignUpModalVisible} onClose={handleCancel} onSwitch={showSignInModal} />
                    </CSSTransition>
                    <CSSTransition
                        in={isSignInModalVisible}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <SignIn visible={isSignInModalVisible} onClose={handleCancel} onSwitch={showSignUpModal} onForget={showForgetModal} />
                    </CSSTransition>
                    <CSSTransition
                        in={isForgetModalVisible}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <Forget visible={isForgetModalVisible} onClose={handleCancel} />
                    </CSSTransition>
                </Span>
            </NavItems>
        );
    };

    return (
        <HeaderSection>
            <Container maxWidth={2000}>
                <Row justify="space-between" align="middle">
                    <LogoContainer to="/" aria-label="homepage">
                        <img src="/logo.svg" alt="PRLah Logo" style={{ width: "119.1px", height: "40.2px" }} />
                    </LogoContainer>
                    <NotHidden>
                        <MenuItem />
                    </NotHidden>
                    <Burger onClick={toggleButton}>
                        <Outline />
                    </Burger>
                </Row>
                <CustomDrawer closable={false} open={visible} onClose={toggleButton} bodyStyle={{ textAlign: 'center' }}>
                    <Col style={{ marginBottom: "2rem" }}>
                        <Label onClick={toggleButton}>
                            <Col span={12}>
                                <Menu>Menu</Menu>
                            </Col>
                            <Col span={12}>
                                <Outline />
                            </Col>
                        </Label>
                    </Col>
                    <DrawerMenu>
                        <MenuItem />
                    </DrawerMenu>
                </CustomDrawer>
            </Container>
        </HeaderSection>
    );
};

export default withTranslation()(Header);
